import { isMybrandAZ, isTabaterraAZ } from './appTypeHelper';

const MYBRAND_AZ_PROMO_CODE_REGEXP = /TB[A-Z0-9]{6}/g;

export const findCodeInDetectionResponse = (detectionResponse: any) => {
  const textAnnotations = detectionResponse?.responses[0]?.textAnnotations;

  if (!textAnnotations) {
    return null;
  }

  for (let i = 1; i < textAnnotations.length; i++) {
    const text = textAnnotations[i].description as string;

    if (isTabaterraAZ() && text.match(MYBRAND_AZ_PROMO_CODE_REGEXP)) {
      return text;
    }

    if (isMybrandAZ() && text.length >= 9) {
      return text;
    }
  }

  return null;
};

export const cropImageCenter = (base64Str: string, size = 500): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = base64Str;

    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img;

      const side = Math.min(width, height);
      const sx = (width - side) / 2;
      const sy = (height - side) / 2;

      const canvas = document.createElement('canvas');
      canvas.width = size;
      canvas.height = size;
      const ctx = canvas.getContext('2d')!;

      ctx.drawImage(img, sx, sy, side, side, 0, 0, size, size);

      const croppedImage = canvas.toDataURL('image/jpeg');
      resolve(croppedImage);
    };

    img.onerror = (err) => reject(err);
  });
};
