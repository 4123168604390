import { BonusShopBlackIcon as BonusShopBlackIconThin } from '../../icons/thin/bonus-shop-black';
import { BonusShopIcon as BonusShopIconThin } from '../../icons/thin/bonus-shop';
import { ChatBlackIcon as ChatBlackIconThin } from '../../icons/thin/chat-black';
import { ChatIcon as ChatIconThin } from '../../icons/thin/chat';
import { HomeBlackIcon as HomeBlackIconThin } from '../../icons/thin/home-black';
import { HomeIcon as HomeIconThin } from '../../icons/thin/home';
import { KnowledgeBaseBlackIcon as KnowledgeBaseBlackIconThin } from '../../icons/thin/knowledge-base-black';
import { KnowledgeBaseIcon as KnowledgeBaseIconThin } from '../../icons/thin/knowledge-base';
import { ProfileBlackIcon as ProfileBlackIconThin } from '../../icons/thin/profile-black';
import { ProfileIcon as ProfileIconThin } from '../../icons/thin/profile';
import { MyBrandFooterHomeIcon } from '../../icons/mybrand_footer_home_icon';
import { MyBrandFooterShopIcon } from '../../icons/mybrand_footer_shop_icon';
import {
  HomeIcon,
  HomeBlackIcon,
  BonusShopIcon,
  BonusShopBlackIcon,
  KnowledgeBaseIcon,
  KnowledgeBaseBlackIcon,
  ProfileIcon,
  ProfileBlackIcon,
  ChatIcon,
  ChatBlackIcon,
} from '../../icons';
import { MyBrandFooterLibraryIcon } from '../../icons/mybrand_footer_library_icon';
import { MyBrandFooterNewsIcon } from '../../icons/mybrand_footer_news_icon';
import { MyBrandFooterProfileIcon } from '../../icons/mybrand_footer_profile_icon';

export const AppSections = {
  HOME: 'HOME',
  BONUS_SHOP: 'BONUS_SHOP',
  KNOWLEDGE_BANK: 'KNOWLEDGE_BANK',
  CHAT: 'CHAT',
  PROFILE: 'PROFILE',
} as const;

type AppSectionsType = typeof AppSections[keyof typeof AppSections];

type IconSet = { DEFAULT: JSX.Element; ACTIVE: JSX.Element };
type ThemeIcons = Record<AppSectionsType, IconSet>;
type IconsConfigType = Record<string, ThemeIcons[]>;

const iconsConfig: IconsConfigType = {
  MYBRAND_AZ: [
    {
      [AppSections.HOME]: {
        DEFAULT: <HomeIconThin />,
        ACTIVE: <HomeBlackIconThin />,
      },
      [AppSections.BONUS_SHOP]: {
        DEFAULT: <BonusShopIconThin />,
        ACTIVE: <BonusShopBlackIconThin />,
      },
      [AppSections.KNOWLEDGE_BANK]: {
        DEFAULT: <KnowledgeBaseIconThin />,
        ACTIVE: <KnowledgeBaseBlackIconThin />,
      },
      [AppSections.CHAT]: {
        DEFAULT: <ChatIconThin />,
        ACTIVE: <ChatBlackIconThin />,
      },
      [AppSections.PROFILE]: {
        DEFAULT: <ProfileIconThin />,
        ACTIVE: <ProfileBlackIconThin />,
      },
    },
    {
      [AppSections.HOME]: {
        DEFAULT: <MyBrandFooterHomeIcon />,
        ACTIVE: <MyBrandFooterHomeIcon color="rgba(37, 43, 57, 1)" />,
      },
      [AppSections.BONUS_SHOP]: {
        DEFAULT: <MyBrandFooterShopIcon />,
        ACTIVE: <MyBrandFooterShopIcon color="rgba(37, 43, 57, 1)" />,
      },
      [AppSections.KNOWLEDGE_BANK]: {
        DEFAULT: <MyBrandFooterLibraryIcon />,
        ACTIVE: <MyBrandFooterLibraryIcon color="rgba(37, 43, 57, 1)" />,
      },
      [AppSections.CHAT]: {
        DEFAULT: <MyBrandFooterNewsIcon />,
        ACTIVE: <MyBrandFooterNewsIcon color="rgba(37, 43, 57, 1)" />,
      },
      [AppSections.PROFILE]: {
        DEFAULT: <MyBrandFooterProfileIcon />,
        ACTIVE: <MyBrandFooterProfileIcon color="rgba(37, 43, 57, 1)" />,
      },
    },
  ],
  DEFAULT: [
    {
      [AppSections.HOME]: {
        DEFAULT: <HomeIconThin />,
        ACTIVE: <HomeBlackIconThin />,
      },
      [AppSections.BONUS_SHOP]: {
        DEFAULT: <BonusShopIconThin />,
        ACTIVE: <BonusShopBlackIconThin />,
      },
      [AppSections.KNOWLEDGE_BANK]: {
        DEFAULT: <KnowledgeBaseIconThin />,
        ACTIVE: <KnowledgeBaseBlackIconThin />,
      },
      [AppSections.CHAT]: {
        DEFAULT: <ChatIconThin />,
        ACTIVE: <ChatBlackIconThin />,
      },
      [AppSections.PROFILE]: {
        DEFAULT: <ProfileIconThin />,
        ACTIVE: <ProfileBlackIconThin />,
      },
    },
    {
      [AppSections.HOME]: {
        DEFAULT: <HomeIcon />,
        ACTIVE: <HomeBlackIcon />,
      },
      [AppSections.BONUS_SHOP]: {
        DEFAULT: <BonusShopIcon />,
        ACTIVE: <BonusShopBlackIcon />,
      },
      [AppSections.KNOWLEDGE_BANK]: {
        DEFAULT: <KnowledgeBaseIcon />,
        ACTIVE: <KnowledgeBaseBlackIcon />,
      },
      [AppSections.CHAT]: {
        DEFAULT: <ChatIcon />,
        ACTIVE: <ChatBlackIcon />,
      },
      [AppSections.PROFILE]: {
        DEFAULT: <ProfileIcon />,
        ACTIVE: <ProfileBlackIcon />,
      },
    },
  ],
};

export const getIcon = (
  themeId: number,
  appSection: AppSectionsType,
  isActive: boolean,
  appType: string = 'DEFAULT',
) => {
  const appIcons = iconsConfig[appType] ?? iconsConfig.DEFAULT;
  const sectionIcons = appIcons[themeId];

  if (!sectionIcons) {
    throw new Error(`Invalid themeId: ${themeId} for appType: ${appType}`);
  }

  const { ACTIVE, DEFAULT } = sectionIcons[appSection]!;
  return isActive ? ACTIVE : DEFAULT;
};
