import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { modalActions } from '../containers/Modal/modalSlice';
import { authActions } from '../../redux/auth/authSlice';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { CONFIG_KEYS, isFeatureEnabled } from '../../config';
import { subscribePushEvents } from '../../helpers/subscribePushNotifications';
import useFeatureToggles from '../../hooks/useFeatureToggles';
import { Routes } from '../../const';
import styles from './LogoutDeleteAccount.module.scss';

interface LogoutDeleteAccountProps {
  className?: string;
}

export const LogoutDeleteAccount = ({ className }: LogoutDeleteAccountProps) => {
  const { formatMessage } = useInternationalization();
  const { isPasswordAuthEnabled } = useFeatureToggles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(
      modalActions.openPromptModal({
        title: 'Do you want to log out?',
        description: 'Tap “Yes” to continue',
        btnText: 'Yes',
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
          dispatch(authActions.logout());
        },
      }),
    );
  };

  const removeAccount = () => {
    dispatch(
      modalActions.openPromptModal({
        title: 'Are you sure?',
        description: 'Removing your account is irrevocable. You can no longer recover your data',
        btnText: 'Proceed',
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
          dispatch(authActions.removeAccount());
        },
      }),
    );
  };

  const handlePasswordChange = () => {
    history.push(Routes.PasswordChange);
  };

  return (
    <div className={className}>
      {isFeatureEnabled(CONFIG_KEYS.PUSH_NOTIFICATIONS_ENABLED) && (
        <p onClick={subscribePushEvents}>{formatMessage('profile.push_notifications')}</p>
      )}
      {isPasswordAuthEnabled && <p onClick={handlePasswordChange}>{formatMessage('profile.change_password')}</p>}
      <div className={styles.logoutRemoveAccountBlock}>
        <p onClick={handleLogout}>{formatMessage('Log out')}</p>
        <p onClick={removeAccount}>{formatMessage('Remove account')}</p>
      </div>
    </div>
  );
};
