import axios from 'axios';
import { API_URL } from './baseApi';

const GOOGLE_CLOUD_API_KEY = 'AIzaSyB80yvpx2rYI9RE0ROMqXWHc2chiUwx_Tg';
const GOOGLE_CLOUD_BASE_API = 'https://eu-vision.googleapis.com/v1/';
const redundant_string = 'data:image/jpeg;base64,';

export const passportApi = {
  uploadPassportPhoto: async (formData) => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return await axios.post(`${API_URL}/v3/services/file`, formData, config);
  },
  getTextFromPhotoBase64: async (passport_photo) => {
    const formatedImageString = passport_photo.replace(redundant_string, '');
    const body = JSON.stringify({
      requests: [
        {
          image: {
            content: formatedImageString,
          },
        },
      ],
    });

    const response = await fetch(`${GOOGLE_CLOUD_BASE_API}images:annotate?key=${GOOGLE_CLOUD_API_KEY}`, {
      method: 'POST',
      body,
    });

    return response.json();
  },
  getTextFromPhoto: async (passport_photo) => {
    const body = JSON.stringify({
      requests: [
        {
          image: {
            source: {
              imageUri: passport_photo,
            },
          },
          features: [
            {
              type: 'TEXT_DETECTION',
            },
          ],
        },
      ],
    });

    const response = await fetch(`${GOOGLE_CLOUD_BASE_API}images:annotate?key=${GOOGLE_CLOUD_API_KEY}`, {
      method: 'POST',
      body,
    });

    return response.json();
  },
  setPassportData: async (passportData) => {
    return await axios.post(`${API_URL}/v3/passport`, { ...passportData });
  },
  signStatement: async (signature_url) => {
    return await axios.post(`${API_URL}/v3/passport/signature`, { signature: signature_url });
  },
  getPassportData: async () => {
    return await axios.get(`${API_URL}/v3/passport`);
  },
};
