import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import Webcam from 'react-webcam';
import yn from 'yn';

import styles from './PromoCodeScan.module.scss';
import { cropImageCenter, findCodeInDetectionResponse } from '../../helpers/cloudVisionHelper';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { base64ToFile } from '../../helpers/dataFormattingHelpers';
import { ArrowIconRounded } from '../../icons/arrow-icon-rounded';
import { Button } from '../../components/common/Button/Button';
import { isStaging } from '../../helpers/environmentHelper';
import { isMybrandAZ } from '../../helpers/appTypeHelper';
import { passportApi } from '../../api/passportApi';
import { promoCodeApi } from '../../api/promoCodeApi';
import frame from './assets/frame.png';
import { Routes } from '../../const';
import Banner from './Banner';

const videoConstraints: MediaTrackConstraints = {
  facingMode: 'environment',
};

const PromoCodeScan = () => {
  const { t } = useInternationalization();
  const dispatch = useDispatch();
  const history = useHistory();
  const webcamRef = useRef<Webcam>(null);
  const [isPending, setIsPending] = useState(false);
  const [preview, setPreview] = useState<string | null>(null);

  const openErrorModal = useCallback(() => {
    dispatch(
      modalActions.openErrorModal({
        description: t('promo_code_scan.not_recognised_modal.description'),
        btnText: t('promo_code_scan.not_recognised_modal.button'),
        onButtonClick: () => dispatch(modalActions.closeModal()),
      }),
    );
  }, [dispatch]);

  const navigateToPromoCodePage = () => history.push(Routes.PromoCodeRoute);

  const navigateToMainPage = () => history.push(Routes.MainRoute);

  const handleDiscountCodeScan = async () => {
    try {
      setIsPending(true);

      const base64Image = webcamRef.current?.getScreenshot();

      if (!base64Image) {
        throw new Error('Base64Image is missing');
      }

      const base64CroppedImage = await cropImageCenter(base64Image!);

      isStaging() && setPreview(base64CroppedImage);

      let code;

      if (isMybrandAZ()) {
        const { data } = await promoCodeApi.ocr(base64ToFile(base64CroppedImage, 'image.jpg'));

        const isSuccess = yn(data?.success);

        if (!isSuccess) {
          throw new Error(`OCR Error: ${data?.message}`);
        }

        code = data?.message;
      } else {
        const result = await passportApi.getTextFromPhotoBase64(base64CroppedImage);
        code = findCodeInDetectionResponse(result);
      }

      if (!code) {
        throw new Error('Code is missing');
      }

      return history.push(`${Routes.PromoCodeRoute}/?code=${code}`);
    } catch (error) {
      console.warn(error);
      openErrorModal();
    } finally {
      setIsPending(false);
    }
  };

  return (
    <div className={classNames(styles.root, 'themed-background-image')}>
      {preview && <img className="preview" src={preview} />}
      <img src={frame} className="frame" />
      <Banner />
      <Button disabled={isPending} onClick={navigateToMainPage} customClass="back-button" withoutTheme>
        <ArrowIconRounded className="icon" />
      </Button>
      <Webcam ref={webcamRef} screenshotFormat="image/jpeg" className="webcam " videoConstraints={videoConstraints} />
      <div className="actions">
        <Button withoutTheme disabled={isPending} onClick={handleDiscountCodeScan}>
          {t('promo_code_scan.capture_button')}
        </Button>
        <Button withoutTheme disabled={isPending} onClick={navigateToPromoCodePage}>
          {t('promo_code.manual_button')}
        </Button>
      </div>
    </div>
  );
};

export default PromoCodeScan;
