import { useSelector } from 'react-redux';
import { getUnreadTopicsSelector } from '../../redux/knowledgeBank/knowledgeBankSelectors';
import { Link } from 'react-router-dom';

import './navigationMenu.scss';
import { Routes, ChatRoutes } from '../../const';
import { isIPhoneWithHomeBar } from '../../helpers/checkIsIphoneX';
import { UncheckedNotificationBadge } from '../common/UncheckedNotificationBadge/UnchakedNotificationBadge';
import { APP_TYPES } from '../../const/appConstants';
import { chatSelectors } from '../../redux/chat';
import { amplitudeHelper } from '../../helpers/ampitudeHelper';
import { CONFIG_KEYS, getConfigFor, isFeatureEnabled } from '../../config';
import { AppSections, getIcon } from './iconsConfig';
import classnames from 'classnames';

export const NavigationMenu = ({ location }) => {
  const unreadNotificationsCount = useSelector(chatSelectors.selectUnreadNotificationsCount);
  const unreadChatMessagesCount = useSelector(chatSelectors.selectUnreadSupportMessagesCount);
  const unreadMessages = unreadNotificationsCount + unreadChatMessagesCount;
  const unreadTopics = useSelector(getUnreadTopicsSelector);
  const menuBottomOffset =
    isIPhoneWithHomeBar() || !isFeatureEnabled(CONFIG_KEYS.SHOW_FOOTER_DISCLAIMER)
      ? '0'
      : window.innerWidth < 360
      ? '30px'
      : '15px';
  const paddingBottom = isIPhoneWithHomeBar() ? '34px' : '0';

  const iconThemeId = getConfigFor(CONFIG_KEYS.DEFAULT_ICONS_THEME_ID);
  const appType = process.env.REACT_APP_APP_TYPE;
  const borderTop = process.env.REACT_APP_APP_TYPE === APP_TYPES.MYBRAND_AZ ? 'none' : ' 1px solid rgba(0, 0, 0, 0.2)';
  const linkRightSpace = process.env.REACT_APP_APP_TYPE === APP_TYPES.MYBRAND_AZ ? '0' : '10px';

  if (!location) {
    return null;
  }
  return (
    <div
      className="navigation-menu"
      style={{
        bottom: menuBottomOffset,
        paddingBottom: paddingBottom,
        borderTop: borderTop,
      }}
    >
      <div className="navigation-menu__top-border" />

      <div className="navigation-menu__buttons">
        <Link
          className={classnames('navigation-menu__link', {
            'navigation-menu__link-space': linkRightSpace,
          })}
          to={Routes.MainRoute}
          onClick={() => {
            amplitudeHelper.openMainScreen();
          }}
        >
          <div className="navigation-menu__buttons-icon-container">
            {getIcon(
              iconThemeId,
              AppSections.HOME,
              [Routes.MainRoute, Routes.ChanceList, Routes.ChallengesRoute].includes(location.pathname),
              appType,
            )}
          </div>
        </Link>

        <Link
          className="navigation-menu__link"
          to={Routes.BonusShopRoute}
          onClick={() => amplitudeHelper.openMainBonusShop()}
        >
          <div className="navigation-menu__buttons-icon-container">
            {getIcon(
              iconThemeId,
              AppSections.BONUS_SHOP,
              [
                Routes.BalanceRoute,
                Routes.PrizesRoute,
                Routes.MoneyForThePhone,
                Routes.MoneyForTheWallet,
                Routes.ChanceRoute,
                Routes.ChallengeRoute,
                Routes.MyVouchers,
              ].includes(location.pathname) ||
                location.pathname.startsWith(Routes.VoucherDetails) ||
                location.pathname.startsWith(Routes.BonusShopRoute),
              appType,
            )}
          </div>
        </Link>

        <Link
          className="navigation-menu__link"
          to={Routes.KnowledgeBankRoute}
          onClick={() => amplitudeHelper.openMainKB()}
        >
          <div className="navigation-menu__buttons-icon-container">
            {unreadTopics > 0 ? <UncheckedNotificationBadge>{unreadTopics}</UncheckedNotificationBadge> : ''}
            {getIcon(
              iconThemeId,
              AppSections.KNOWLEDGE_BANK,
              [Routes.KnowledgeBankRoute, Routes.BrandingBanRoute].includes(location.pathname),
              appType,
            )}
          </div>
        </Link>

        <Link
          className="navigation-menu__link"
          to={ChatRoutes.Notifications}
          onClick={() => amplitudeHelper.openMainChat()}
        >
          <div className="navigation-menu__buttons-icon-container">
            {unreadMessages > 0 ? <UncheckedNotificationBadge>{unreadMessages}</UncheckedNotificationBadge> : ''}
            {getIcon(iconThemeId, AppSections.CHAT, Object.values(ChatRoutes).includes(location.pathname), appType)}
          </div>
        </Link>

        <Link
          className="navigation-menu__link"
          onClick={() => amplitudeHelper.openMainProfile()}
          to={appType === APP_TYPES.DEMO ? Routes.ProgressRoute : Routes.ProfileRoute}
        >
          <div className="navigation-menu__buttons-icon-container">
            {getIcon(
              iconThemeId,
              AppSections.PROFILE,
              [
                Routes.ProfileRoute,
                Routes.CheckInStatusRoute,
                Routes.Moderation,
                Routes.BaStatistic,
                Routes.ProgressRoute,
              ].includes(location.pathname),
              appType,
            )}
          </div>
        </Link>
      </div>
    </div>
  );
};
