export default {
  /* Features */
  BILINGUAL_ENABLED: 'BILINGUAL_ENABLED',
  BONUS_SHOP_ENABLED: 'BONUS_SHOP_ENABLED',
  BONUS_SHOP_PRIZES_TAB_ENABLED: 'BONUS_SHOP_PRIZES_TAB_ENABLED',
  BONUS_SHOP_VOUCHERS_TAB_ENABLED: 'BONUS_SHOP_VOUCHERS_TAB_ENABLED',
  CASHOUT_DEMO_ENABLED: 'CASHOUT_DEMO_ENABLED',
  CIGARETTES_STYLE_WARNING_ENABLED: 'CIGARETTES_STYLE_WARNING_ENABLED',
  DAILY_BONUS_ENABLED: 'DAILY_BONUS_ENABLED',
  GAME_RULES_LINK_ENABLED: 'GAME_RULES_LINK_ENABLED',
  IOS_ADD_TO_HOME_SCREEN_BANNER_ENABLED: 'IOS_ADD_TO_HOME_SCREEN_BANNER_ENABLED',
  MONTHLY_COMPLIANCE_CHECK_ENABLED: 'MONTHLY_COMPLIANCE_CHECK_ENABLED',
  NON_BONUS_VIEW_ENABLED: 'NON_BONUS_VIEW_ENABLED',
  PUSH_NOTIFICATIONS_ENABLED: 'PUSH_NOTIFICATIONS_ENABLED',
  SELF_REGISTRATION_ENABLED: 'SELF_REGISTRATION_ENABLED',
  SUPPORT_WITH_TICKETS_ENABLED: 'SUPPORT_WITH_TICKETS_ENABLED',
  TEAM_CHALLENGES_ENABLED: 'TEAM_CHALLENGES_ENABLED',
  THEME_GALLERY_ENABLED: 'THEME_GALLERY_ENABLED',
  PROFILE_QUIZ_STATS_ENABLED: 'PROFILE_QUIZ_STATS_ENABLED',
  EXTENDED_PROFILE_INFO_ENABLED: 'EXTENDED_PROFILE_INFO_ENABLED',
  INVITE_CODE_ENABLED: 'INVITE_CODE_ENABLED',
  QUIZ_CARD_VIEW_ENABLED: 'QUIZ_CARD_VIEW_ENABLED',
  SHOW_USERNAME_INSTEAD_OF_FIRST_NAME_ENABLED: 'SHOW_USERNAME_INSTEAD_OF_FIRST_NAME_ENABLED',
  FACEBOOK_STYLED_NOTIFICATIONS_ENABLED: 'FACEBOOK_STYLED_NOTIFICATIONS_ENABLED',
  CHAT_WITHOUT_OPTIONS_ENABLED: 'CHAT_WITHOUT_OPTIONS_ENABLED',
  FALLING_SNOWFLAKES_ENABLED: 'FALLING_SNOWFLAKES_ENABLED',
  SHOW_REFFERAL_MODULE_ENABLED: 'SHOW_REFFERAL_MODULE_ENABLED',
  INTERACTIVE_ARTICLE_ENABLED: 'INTERACTIVE_ARTICLE_ENABLED',
  REDUCE_IMAGE_SIZE_ENABLED: 'REDUCE_IMAGE_SIZE_ENABLED',
  SHOW_ZERO_BONUS_BLOCK_ENABLED: 'SHOW_ZERO_BONUS_BLOCK_ENABLED',
  QUIZ_MODE_SWITCHER_ENABLED: 'QUIZ_MODE_SWITCHER_ENABLED',
  SHOW_FOOTER_DISCLAIMER: 'SHOW_FOOTER_DISCLAIMER',
  SHOW_POINTS_AFTER_FINISH_ADDITIONAL_QUIZ: 'SHOW_POINTS_AFTER_FINISH_ADDITIONAL_QUIZ',
  SHOW_GOAL_PROGRESS_ENABLED: 'SHOW_GOAL_PROGRESS_ENABLED',
  ENABLE_ORDER_DETAILS: 'ENABLE_ORDER_DETAILS',
  ENABLE_ONBOARDING_SCREEN: 'ENABLE_ONBOARDING_SCREEN',
  ENABLE_PROMO_CODE_INFO_BUTTON: 'ENABLE_PROMO_CODE_INFO_BUTTON',
  SHOW_PROFILE_CONFIG_ICON: 'SHOW_PROFILE_CONFIG_ICON',
  SHOW_LOGOUT_DELETE_ACCOUNT_BLOCK: 'SHOW_LOGOUT_DELETE_ACCOUNT_BLOCK',
  ENABLE_PROMO_CODE_SCAN: 'ENABLE_PROMO_CODE_SCAN',
  SHOW_REMAINS_PHYSICAL_GOODS_ENABLED: 'SHOW_REMAINS_PHYSICAL_GOODS_ENABLED',
  SHOW_PROFILE_SCORES_ENABLED: 'SHOW_PROFILE_SCORES_ENABLED',

  /* Misc */
  AUTH_METHOD: 'AUTH_METHOD',
  DEFAULT_APP_THEME_ID: 'DEFAULT_APP_THEME_ID',
  DEFAULT_ICONS_THEME_ID: 'DEFAULT_ICONS_THEME_ID',
  SOLO_QUIZ_GAME_TIME_IN_SECONDS: 'SOLO_QUIZ_GAME_TIME_IN_SECONDS',
  REDIRECT_ROUTE_AFTER_PURCHASE: 'REDIRECT_ROUTE_AFTER_PURCHASE',
  BONUS_SHOP_PURCHASE_LIMIT: 'BONUS_SHOP_PURCHASE_LIMIT',
} as const;
