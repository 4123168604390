import axios from 'axios';
import { API_URL } from './baseApi';

export const promoCodeApi = {
  postPromoCode: async (code) => {
    return await axios.post(`${API_URL}/v3/promo-code`, { code });
  },

  ocr: async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    return await axios.post(`${API_URL}/v3/promo-code/ocr`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
};
